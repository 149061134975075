

$('document').ready(function () {
	$('.js-blog-card').responsiveEqualHeightGrid();

	$(".js-header").headroom({
		// vertical offset in px before element is first unpinned
	    offset : 96,
	    // or you can specify tolerance individually for up/down scroll
	    tolerance : {
	        up : 5,
	        down : 0
	    }
	});

	$('.js-burger').click(function(){
		$('.js-header').toggleClass('headroom--fixed');
		$('.js-menu').toggleClass('ty-100p o-0 o-100');
		$('.js-header').toggleClass('headroom--menu-open');

		/*var txt = $(".js-menu").hasClass('is-open') ? 'Close' : 'Explore';

		$('.js-burger .i').fadeOut(200, function(){
			$(this).toggleClass('i--menu');
			$(this).toggleClass('i--menu-close').fadeIn(200);
		});

		$('.js-burger .icon__text').fadeOut(200,function(){
			$(this).text(txt).fadeIn(200);
		});*/
	});

	$('.js-scroll-to').click(function(e){
		var id = $(this).attr("href")
		e.preventDefault();
		$('html, body').animate({
		    scrollTop: $(id).offset().top
		}, 500);
	});

	$('.js-open-modal').click(function(e){
		e.preventDefault();
		$('.js-overlay').fadeIn();
		$('.js-modal').fadeIn();
	});

	$('.js-overlay').click(function(){
		$('.js-overlay').fadeOut();
		$('.js-modal').fadeOut();
	});

	$('.js-modal-close').click(function(){
		$('.js-overlay').fadeOut();
		$('.js-modal').fadeOut();
	});

});


